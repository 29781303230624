<template>
    <div class="reserve">
        <div class="right">
        <div>
            <breadCrumb :siderContent="siderName" />
        </div>
        <div class="top">
            <a-input class="ipt" placeholder="商品名称/条码" v-model="form.keywords" />
            <div class="title">商品分类：</div>
            <a-select class="mySelect" placeholder="请选择"  v-model="form.categoryId">
                <a-select-option value="">全部 </a-select-option>  
                <a-select-option   
                    v-for="(item,index) in cateList"
                    :key="index" 
                    :value="item.category_id"
                    >{{item.name}}
                </a-select-option>  
            </a-select>
            <div  class="title">商品状态：</div>
            <a-select class="mySelect" placeholder="请选择" v-model="form.status">
                 <a-select-option value="">全部 </a-select-option>  
                <a-select-option  value="10" >上架</a-select-option>
                <a-select-option  value="20" >下架 </a-select-option>      
            </a-select>
            <a-button class="myButton OkBtn" @click="search">查询</a-button>
            <a-button class="myButton OkBtn" @click="showAdd=true;add=true">新增商品</a-button>
        </div>
        <div class="piliang">
          <div v-if="permission.indexOf('/clerk/goods/changeRetailPrice') > -1">
            <div class="make" @click="quitButton" v-if="!keep">
              快速修改
            </div>
            <div class="make" @click="keepButton"  v-if="keep">
              保存
            </div>
          </div>
          
          <div class="make" @click="piButton">批量操作</div>
        </div>
        <div>
          <a-config-provider :locale="locale">
            <a-table :scroll="{ y: '62vh' }"
                :columns="columns"
                :data-source="members"
                :rowKey="
                    (record, index) => {
                    return record.goods_id;
                    }
                "
                bordered
                :pagination="{
                    current:searchContent.page,
                    total:total,
                    pageSize:searchContent.listRows,
                    showSizeChanger:true,
                    pageSizeOptions:['30','50','100', '500','1000']
                }"
                @change="handleTableChange"
                :locale="{emptyText: '暂无数据'}"
                :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
            >
              <span slot="action" slot-scope="text, item" >
                  <span style="color:#2a82e4;cursor: pointer;" @click="editButton(item)">编辑</span>
                  <span style="color:#2a82e4;margin-left:30px;cursor: pointer;" @click="showDelete=true;deleteId=item.goods_id;">删除</span>
              </span>
              <span slot="goods_image" slot-scope="text,item">
                  <img  v-if="item.goods_image == null" src="@/assets/defaultImg.png" alt="商品图片" style="width:5.3vw;height:6.1vh"/>
                  <a title="点击查看原图" :href="item.goods_image" target="_blank" v-else>
                  <img width="50" height="50" :src="item.goods_image" alt="商品图片"  style="width:5.3vw;height:6.1vh"/>
                  </a>
              </span>
              <span slot="goods_price_min" slot-scope="text,record">
                <span v-if="keep==false  || selectedRowKeys.indexOf(record.goods_id) ==-1">￥{{text}}</span>
                <span v-if="keep==true && selectedRowKeys.indexOf(record.goods_id) !=-1" style="display:flex;align-items:center">
                  <div>￥</div>
                  <a-input-number :min="0" v-model="record.goods_price_min"></a-input-number>
                </span>
              </span>
              <span slot="sort" slot-scope="text,record">
                <span v-if="keep==false || selectedRowKeys.indexOf(record.goods_id) ==-1">{{text}}</span>
                <span v-if="keep==true && selectedRowKeys.indexOf(record.goods_id) !=-1">
                  <a-input-number :min="0" v-model="record.sort"></a-input-number>
                </span>
              </span>
              <span slot="status" slot-scope="text">
                <span v-if="text==10"  style="color:#52c41a">上架</span>
                <span v-if="text==20"  style="color:#f5222d">下架</span>
              </span>
              <span slot="spec_type" slot-scope="text">
                <span v-if="text==20">是</span>
                <span v-if="text==10">否</span>
              </span>
            </a-table>
          </a-config-provider>
          </div>
        </div>
        <deleteTip :isShow="showDelete" :num="deleteId" @cancel="showDelete=false" @update="getGoodsList"/>
        <addGoods :showModal="showAdd" :list="everyList" :add="add" :edit="edit" :num="deleteId" @closeAddGoods="showAdd=false;add=false;edit=false" @update="getGoodsList"/>
        <batchModal :showModal="showBatch" :selectedRowKeys="selectedRowKeys" @closeAddGoods="showBatch=false" @update="getGoodsList();selectedRowKeys=[]"/>
    </div>
</template>
<script>
import breadCrumb from "@/components/BreadCrumb/BreadCrumb";
import {categoryList,goodsLists} from '@/request/business.js'
import {fastUpdateGoods} from '@/request/stock.js'
import deleteTip from './deleteTip.vue'
import addGoods from '@/viewsFastFood/yingye/modules/addGoods.vue'
import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN';
import batchModal from "./batch.vue"
import { mapState } from "vuex";

const columns = [
  {
    title: "商品图片",
    dataIndex: "goods_image",
    scopedSlots: { customRender: 'goods_image' },
    width:89
  },
  {
    title: "商品名称",
    dataIndex: "goods_name",
    width:125
  },
  {
    title: "是否多规格",
    dataIndex: "spec_type",
    scopedSlots: { customRender: 'spec_type' },
    width:75
  },
  {
    title: "商品价格",
    dataIndex: "goods_price_min",
    scopedSlots: { customRender: 'goods_price_min' },
    width:120
  },
   {
    title: "排序",
    dataIndex: "sort",
    scopedSlots: { customRender: "sort" },
    width:100
  },
  {
    title: "库存",
    dataIndex: "stock_total",
    width:65
  },
  {
    title: "状态",
    dataIndex: "status",
    scopedSlots: { customRender: "status" },
    width:63
  },
   {
    title: "操作",
    dataIndex: "action",
    scopedSlots: { customRender: "action" },
    width:110
  },
];
export default {
  data() {
    return {
        locale: zhCN,
        siderName: "商品管理", //sider切换内容
        cateList:[],
        columns,
        members: [],
        form:{
          page:1,
          listRows:30,
          keywords:"",//商品编码or商品名称
          categoryId:undefined,
          status:undefined,//status（10上架 20下架
        },
        searchContent:{
          page:1,
          listRows:30,
          keywords:"",//商品编码or商品名称
          categoryId:undefined,
          status:undefined,//status（10上架 20下架
        },
        total:1,
        showDelete:false,
        deleteId:"",
        showAdd:false,
        everyList:{},
        add:false,//确保点击的新增商品按钮
        edit:false,//确保点击的编辑按钮
        selectedRowKeys: [], 
        showBatch:false,
        keep:false,
    };
  },
  components: {
    breadCrumb,deleteTip,addGoods,batchModal
  },
  computed: {
    ...mapState(["permission"]),
  },
  mounted(){
    this.tabRequest()
    this.getGoodsList()
  },
  updated(){
  },
  methods:{
    search(){
      this.searchContent.page=1;
      this.getGoodsList()
    },
    handleTableChange(e){ //点击分页
      this.form.page = e.current;
      this.form.listRows = e.pageSize;
      this.getGoodsList()
    },
    tabRequest(){  //商品分类请求
      categoryList().then((res) => {
          if(res.data.status===200){
              const list =res.data.data.list && res.data.data.list;
              this.cateList = list;
          }           
      })
    },
    getGoodsList(){
      this.searchContent=this.form;
      goodsLists(this.searchContent).then((res) => {
          if(res.data.status===200){
              const list =res.data.data.list.data && res.data.data.list.data;
              this.members=list
              this.total=res.data.data.list.total
          }           
      }) 
    },
    editButton(item){
        if(item.spec_type==10){
          this.showAdd=true;
          this.everyList=item;
          this.edit=true;
          this.deleteId=item.goods_id;
        }else if(item.spec_type==20){
            this.$message.error('多规格商品暂不支持客户端编辑！如需编辑请前往商家后台')
        }
        
    },
    onSelectChange(selectedRowKeys){
      this.selectedRowKeys=selectedRowKeys
    },
    piButton(){
      if(this.selectedRowKeys.length>0){
        this.showBatch=true
      }else{
        this.$message.error("请勾选商品")
      }
    },
    quitButton(){
      if(this.selectedRowKeys.length>0){
        this.keep=true
      }else{
        this.$message.error("请勾选商品")
      }
    },
    keepButton(){
      const arr= this.members.filter(item=>{
        if(this.selectedRowKeys.indexOf(item.goods_id) !=-1){
          return item
        }
      })
      const  res = arr.findIndex((v) => {
        return v.spec_type == '20';
      });
      console.log(res)
      if(res!=-1){
        this.$message.error('多规格商品暂不支持客户端快速修改！如需修改，请前往商家后台进行修改')
      }else{
        const brr=[];
        for(let i=0;i<arr.length;i++){
          brr.push({goods_id:arr[i].goods_id,goods_price:arr[i].goods_price_min,sort:arr[i].sort})
        }
        const parmas={goodsList:brr}
        fastUpdateGoods(parmas).then((res) => {
          if(res.data.status===200){
            this.$message.success('快速修改成功')
            this.getGoodsList()
            this.keep=false
            this.selectedRowKeys=[]
          }           
        })
      }
    }
  }
};
</script>

<style lang="less" scoped>
.reserve {
  width: 100%;
  height: 95.4vh;
  .right {
    width: 83vw;
    height: 95.4vh;
    overflow-y: auto;
  }
}
/deep/.ant-table{
  margin: 0 2.5vh 0 2.5vh;
}
.top {
  display: flex;
  margin: 1.5vh;
  .title{
      height: 3.9vh;
      line-height: 3.9vh;
  }
  .ipt {
    width: 16.7vw;
    margin-left: 1vh;
    margin-right: 2.5vh;
    height:3.9vh;
  }
  .myRange {
    width: 37vh;
    margin-right: 2.5vh;
  }
  .mySelect {
    width: 11.7vw;
    margin-right: 2.5vh;
  }
  .myButton {
    height:3.9vh;
    width: 7.8vw !important;
    margin-right: 2.5vh;
    padding:0 !important;
    border: none;
    color: white;
    font-size: 1.8vh;
      span{
        display: inline-block;
        width: 10vh !important;
      }
  }
  .button {
    width: 10vh;
    height:3.9vh;
    font-size: 1.8vh;
  }
  .myTable{
    margin: 2.5vh;
  }
}
/deep/.ant-pagination{
  margin-right:4vw;
}
/deep/td{
  padding:1.5vh !important;
  background: white !important;
  font-size: 1.8vh;
}  
/deep/th{
  background: rgba(248, 248, 255, 1) !important;
  font-size: 1.8vh;
}
/deep/.ant-calendar-picker-input{
   font-size: 1.8vh;
   height: 3.9vh;
}
/deep/.ant-select-selection__rendered{
 font-size: 1.8vh;
  height: 3.9vh;
  line-height: 3.9vh;
}
/deep/.ant-select-selection--single{
 height: 3.9vh;
  line-height: 3.9vh;
}
/deep/.ant-input-number{
  width:8vw;
  height:4.5vh;
}
/deep/.ant-input-number-input{
  width:8vw;
  height:4.5vh;
}
.piliang{
  display: flex;
  margin-left:2.5vh;
  margin-bottom: 1.5vh;
  .make:first-child{
    margin-right: 1.54vw;
  }
  .make{
    width:8.7vw;
    line-height: 3.9vh;
    border: 1px solid #ff6600;
    border-radius: 4px;
    text-align: center;
    color:#ff6600;
    cursor: pointer;
    font-size: 1.8vh;
  }
}
/deep/th{
   padding:10px !important
 } 
</style>