<template>
  <div>
    <a-modal
      :visible="isShow"
      title="删除提示"
      cancel-text="取消"
      ok-text="确定"
      @ok="handleOk"
      @cancel="handleCancel"
    >
      <div class="font">您确定删除该商品记录吗？删除后不可恢复！</div>
    </a-modal>
  </div>
</template>
<script>
import {deleteGoods} from '@/request/business.js'
export default {
  props: ["isShow",'num'],

  data() {
    return {
    };
  },
  methods: {
    handleOk() {  //确定按钮
      deleteGoods({goodsIds:[this.num]}).then((res) => {
            if(res.data.status===200){
              this.$message.success('删除成功')
              this.$emit('cancel',false)//关闭按钮
              this.$emit('update',false)//关闭按钮  
            }           
        }) 
       
    },
    handleCancel(){ //取消按钮
      this.$emit('cancel',false)//关闭按钮
    }, 
  },
  updated(){
    console.log(JSON.stringify(this.num))
  }
};
</script>
 <style lang="less" scoped>
 .button{
  margin: 1vh;
  width: 130px !important ;
  height: 35px !important ;
  font-size: 1.8vh;
}
/deep/.ant-btn {
  width: 8vw;
  height: 4.6vh;
  font-size: 1.8vh;
  margin-right: 2vw;
  margin-left: 1vw;
}
/deep/.ant-modal-footer button + button {
  width: 8vw;
  height: 4.6vh;
  font-size: 1.8vh;
  border-radius: 0.5vh;
  color: white;
  margin-left: 5vh;
}
/deep/.ant-modal-footer {
  text-align: center;
  margin-top: 3vh;
}
/deep/.ant-modal-title {
  font-size: 1.8vh;
  background: #edeef2 !important;
}
/deep/.ant-radio-wrapper{
  font-size: 1.8vh;
}
/deep/.ant-radio-checked .ant-radio-inner{
   border-color: #ff6600;
}
/deep/.ant-radio-inner::after {
    background-color: #ff6600;
}
/deep/.ant-btn:hover, .ant-btn:focus {
  border-color: rgba(208, 208, 208, 1)
}
/deep/.ant-radio-checked .ant-radio-inner:hover{
   border-color: rgba(208, 208, 208, 1)
}
.font{
  font-size: 1.8vh;
}
/deep/.ant-modal-content{
  background: #edeef2 !important;
}
/deep/.ant-modal-header{
   background: #edeef2 !important;
}
</style>