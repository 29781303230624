<template>
    <div class="weighing">
        <!-- 商品扫描提示 -->
        <a-modal  title="批量操作" :visible="showModal" width="60vw" @cancel="handleCancel">
            <div  class= 'content addGoods'>
                <a-form-model  ref="ruleForm" :model="form" :rules="rules" :label-col="{ span: 4 }" :wrapper-col="{ span: 4}">
                    <a-form-model-item label="商品分类" prop="region" class="right">
                        <div>
                            <a-select v-model="form.category_id" placeholder="请选择" style="width:24vw;height:50px" @click.stop="is_blur=false" dropdownClassName="dropdownstyle">
                                <a-select-option :value="item.category_id" v-for="(item,index)  in cateList" :key="index">
                                    {{item.name}}
                                </a-select-option>
                            </a-select>
                        </div>
                    </a-form-model-item>
                    <a-form-model-item label="商品状态" prop="status" class="right">
                        <a-radio-group v-model="form.status">
                            <a-radio value="10">
                            上架
                            </a-radio>
                            <a-radio value="20">
                            下架
                            </a-radio>
                        </a-radio-group>
                    </a-form-model-item>
                    <a-form-model-item  label="会员余额结算" class="right" >
                         <a-radio-group v-model="form.is_user_balance">
                            <a-radio value="1">
                            开启
                            </a-radio>
                            <a-radio value="2">
                            关闭
                            </a-radio>
                        </a-radio-group>
                    </a-form-model-item>
                    <a-form-model-item  label="是否超卖" class="right" >
                        <a-switch v-model="chao"  @change="chaoMai" un-checked-children="否" checked-children="是" />
                    </a-form-model-item>
                    <a-form-model-item  label="设置店员提成" class="right" >
                        <a-switch v-model="commission"  @change="commissionButton" un-checked-children="否" checked-children="是" />
                    </a-form-model-item>
                    <a-form-model-item ref="commission_percent" prop="commission_percent" label="提成比例" class="top" v-if="commission">
                        <div style="display:flex">
                             <a-input-number v-model="form.commission_percent" :min="0" :max="100"/> &nbsp;&nbsp;%
                        </div>
                       
                    </a-form-model-item>
                    <a-form-model-item  label="口味" class="right" >
                        <a-switch v-model="taste"  @change="tasteButton" un-checked-children="否" checked-children="是" />
                    </a-form-model-item>
                    <a-form-model-item label="口味配置" prop="taste" v-if="taste">
                        <a-radio-group v-model="form.taste">
                            <a-radio v-for="(item,index) in tasteArr" :key="index" :value="item">
                                {{item}}
                            </a-radio>
                        </a-radio-group>
                    </a-form-model-item>
                     <a-form-model-item  label="做法" class="right" >
                        <a-switch v-model="practice"  @change="practiceButton" un-checked-children="否" checked-children="是" />
                    </a-form-model-item>
                    <a-form-model-item label="做法配置" prop="practice" v-if="practice">
                        <a-radio-group v-model="form.practice">
                            <a-radio v-for="(item,index) in practiceArr" :key="index" :value="item">
                                {{item}}
                            </a-radio>
                        </a-radio-group>
                    </a-form-model-item>
                </a-form-model>
            </div>
            <template slot="footer">
                <a-button class="clearBtn textColor" @click="handleCancel">关闭</a-button>
                <a-button  class="OkBtn" @click="handleOk()">确定</a-button>
            </template>
        </a-modal>
    </div>
</template>
<script>
import {categoryList} from '@/request/business.js'
import {batchUpdateGoods,getPractice}from '@/request/stock.js'
export default {
    name:'weighing',
    props:{
        showModal:{
            default:false,
            type:Boolean
        },
        selectedRowKeys:{}
    },
    data(){
        return{
            type:[{value:'常规商品',id:1},{value:'称重商品',id:2},],
            form:{
                category_id:undefined,//商品分类
                status:'10',//商品状态(10上架 20下架)
                is_user_balance:'1',//是否允许使用余额支付：1允许2不允许
                is_commission:1,//是否开启提成：1关闭2开启
                commission_percent:"",//提成比例
                is_taste:1,//是否开启口味：1未开启2已开启
                taste:"",//口味配置
                is_practice:1,//是否开启做法：1未开启2已开启
                practice:"",//做法配置，多个逗号分割
                is_oversold:2,//是否允许超卖：1不允许2允许
            },
            rules:{
                practice:[{ required: true, message: '请选择做法', trigger:'change'}],
                taste:[{ required: true, message: '请选择忌口', trigger:'change'}],
                commission_percent:[{ required: true, message: '请设置提成比例', trigger:'change'},]
            },
            cateList:[],
            chao:true,//超卖
            commission:false,//设置提成
            taste:false,
            practice:false,
            tasteArr:[],//口味数组
            practiceArr:[],//做法数组
        }
    },
    updated(){
    },
    watch:{
        showModal(val){
            if(val){
                getPractice().then(res => {
                    if(res.data.status === 200){
                        const data=res.data.data && res.data.data;
                        this.tasteArr=data.taste?data.taste.split(","):[]
                        this.practiceArr=data.practice?data.practice.split(","):[]
                    }
                }) 
                this.tabRequest()
            }
        },
    },
    methods:{
        handleCancel(){
            this.$emit('closeAddGoods')
            this.form= {
                category_id:undefined,//商品分类
                status:'10',//商品状态(10上架 20下架)
                is_user_balance:'1',//是否允许使用余额支付：1允许2不允许
                is_commission:1,//是否开启提成：1关闭2开启
                commission_percent:"",//提成比例
                is_taste:1,//是否开启口味：1未开启2已开启
                taste:"",//口味配置
                is_practice:1,//是否开启做法：1未开启2已开启
                practice:"",//做法配置，多个逗号分割
                is_oversold:2,//是否允许超卖：1不允许2允许   
            }
            this.chao=true//超卖
            this.commission=false//设置提成
            this.taste=false
            this.practice=false
            this.tasteArr=[]//口味数组
            this.practiceArr=[]//做法数组
           
        },
        handleOk(){
            this.form.goods_ids=this.selectedRowKeys.toString()
            console.log(JSON.stringify(this.form))
            this.$refs.ruleForm.validate(valid => {
                if (valid) {
                    batchUpdateGoods(this.form).then(res => {
                        if(res.data.status === 200){
                            this.$message.success("批量修改成功")
                            this.$emit('update')
                            this.handleCancel()
                        }
                    })
                }
            });
        },
        tabRequest(){  //商品分类请求
            categoryList().then((res) => {
                if(res.data.status===200){
                    const list =res.data.data.list && res.data.data.list;
                    this.cateList = list;
                }           
            })
        },
        chaoMai(checked){
          this.form.is_oversold=checked?2:1
        },
        commissionButton(checked){
            this.form.is_commission=checked?2:1
        },
        tasteButton(checked){
            this.form.is_taste=checked?2:1
        },
        practiceButton(checked){
            this.form.is_practice=checked?2:1
        }
    }
}
</script>
<style lang="less" scoped>
/deep/.ant-modal{
    top: 55px;
}
/deep/.ant-modal-header{
    padding:20px 24px;
    .ant-modal-title{
        text-align: center;
        font-weight: bold;
        font-size: 2.3vh !important;
        color:#505050;
    }
}
/deep/.ant-select {
    height:4.9vh !important
}
/deep/.ant-modal-body{
    padding:0 10px;
    .content{
        .title{
            font-size: 2.3vh;
            font-weight: bold;
            margin-left:1.4vw;
            margin-top: 1.5vh;
        }
        .ant-form{
            .right{
              margin-right: 1.4vw;
              margin-left:0;
            }
            .top{
                margin-top:1.4vh !important;
            }
            .ant-form-item{
                margin-top: 2.6vh;
                margin-bottom: 0;
                width:33vw;
                margin-left:1.4vw;
                .ant-form-item-label{
                    width:8.5vw !important;
                    height: 4.9vh;
                    line-height: 4.9vh;
                    text-align: right;
                    margin-right:1vw;
                    label{
                        color:#505050 !important;
                        text-align: right;   
                    }
                }
                .type{
                    display: flex;
                    font-size: 1.8vh;
                    height: 4.9vh;
                    line-height: 4.9vh;
                    .normal{
                        font-size: 1.8vh;
                        border-radius: 4px;
                        width: 8.35vw;
                        height: 4.9vh;
                        line-height: 4.9vh;
                        border: 1px solid #cccccc;
                        text-align: center;
                        color:#d95e58;
                        margin-right:1.9vh;
                        cursor: pointer;
                    }
                }
                .ant-radio-group{
                    display: flex;
                    height: 4.9vh;
                    line-height: 4.9vh;
                    align-items: center;
                }
                .ant-input{
                    width:24vw;
                    height:4.9vh;
                }
                .ant-input-prefix{
                    color: #d2d2d2;
                }
                .ant-form-extra{
                    color:#999999;
                    font-size: 1.6vh;
                    width:24vw;
                }
               .ant-select-selection__rendered,.ant-select-selection--single,.ant-select-selection-selected-value{
                    width:24vw;
                    height:4.9vh;
                    line-height: 4.9vh;
                }
                .grade{
                    width:24vw;
                    height:4.9vh;
                    display: flex;
                    line-height: 4.9vh;
                    margin-bottom: 1vh;
                    .type{
                        line-height: 4.9vh;
                        background: #fafafa;
                        border: 1px solid #d9d9d9;
                        width:7.8vw;
                        height: 4.9vh;
                        justify-content: center;
                        display: flex;
                        align-items: center;
                        border-right: 0;
                    }
                }
            }
        }
    }
}
/deep/.ant-modal-footer{
    text-align: center;
    border-top:0 ;
    padding-bottom: 20px;
}
.OkBtn,.clearBtn{
    width:11.1vw;
    padding:0;
}
.OkBtn{
    margin-left: 2.6vw;
}
.add{
    width:15.9vw;
}
.ge{
    width:80vw;
    height:1px;
    background: #e3e3e3;
    margin-top: 2.6vh;
} 
/deep/.ant-switch-checked{
    background:#d95e58 ;
} 
/deep/.ant-radio-checked .ant-radio-inner{
    border-color: #d95e58;
}
/deep/.ant-radio-inner::after{
    background-color:#d95e58;
}
/deep/.span.ant-radio + *{
    padding-left: 0;
}
/deep/.ant-form-explain{
    width: 20vw;
}
 /deep/.ant-input-number,/deep/.ant-input-number-input{
    height:4.9vh !important;
    border-radius: 0;
}
/deep/.ant-select-selection__placeholder,/deep/.ant-select-selection-selected-value {
    font-size: 1.8vh;
}
/deep/.dropdownstyle{
    font-size: 1.8vh !important;
}
/deep/.ant-input-number-input{
    height: 4.9vh;
    width:8vw;
}
</style>