import { render, staticRenderFns } from "./deleteTip.vue?vue&type=template&id=76258127&scoped=true&"
import script from "./deleteTip.vue?vue&type=script&lang=js&"
export * from "./deleteTip.vue?vue&type=script&lang=js&"
import style0 from "./deleteTip.vue?vue&type=style&index=0&id=76258127&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "76258127",
  null
  
)

export default component.exports